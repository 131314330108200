<template>
  <div>
    <section class="main_home_page_section_view" >
      <div class="container-fluid">
        <div class="top_bar_view">
          <div  class="push_logo"></div>
          <img
            :src="LogoImage"
            alt="logo"
            class="img-fluid"
          >
          <div class="button_view_box">
            <div
              class="button_view"
              @click="$router.push({name: 'login'}).catch(() => {})"
            >
              Login
            </div>
            <!-- <div
              class="button_view orange"
              @click="$router.push({name: 'register'}).catch(() => {})"
            >
              Register
            </div> -->
          </div>
        </div>
        <div class="heading_view">
          <h4>Professional Services For Your Home & Commercial</h4>
        </div>
      </div>
    </section>
    <SiteFooter />
  </div>

</template>

<script>
import { ref } from '@vue/composition-api'
import SiteFooter from '../components/site/SiteFooter.vue'
import Logo from '../../assets/images/logo/logo.png'
import Background from '../../assets/images/bg/bg.webp'

export default {

  components: {
    SiteFooter,
  },
  setup() {
    const LogoImage = ref(Logo)
    const BackgroundImage = ref(Background)
    return {
      LogoImage,
      BackgroundImage,
    }
  },
  mounted() {
    const image = new Image()
    image.src = '../../assets/images/bg/bg.webp'
    image.onload = () => {
    // Add a class to the element once the image is loaded
      this.$el.classList.add('background-image')
    }
  },
}
</script>

<style scoped>
.background-image {
  background-image: url(../../assets/images/bg/bg.webp);
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
